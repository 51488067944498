import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthenticationCheckService } from './authentication-check.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  /**
   * @author kailash_dev6
   * @param AuthGuardService
   * Precheck brfore redirecting to another page.
   * < Write less & code more />
   */
  constructor(private auth: AuthenticationCheckService, private router: Router,) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let authToken = this.auth.isAuthenticated();
    let pagePath = state.url;
    //
    if (authToken == true) localStorage.setItem('login', 'true')
    if (authToken == false) localStorage.setItem('login', 'false')

    if (authToken) {
      return true;
    } else if (authToken == false) {
      this.router.navigateByUrl('/signin', { replaceUrl: true });
    }
    // not logged in so redirect to login page with the return url and return false
    // this.router.navigate(['login'], { queryParams: { returnUrl: state.url }});
    return false;
  }
}
